<template>
    <v-container class="pa-6" fluid>
        <div v-if="loading" class="page-preloader local"/>
        <template v-else>
            <div class="def-page-cont pt-16" v-if="projectData">
                <div class="changelog">
                    <div class="changelog__title">Changelog</div>
                    <div class="changelog__title_sub mt-7">Current Values</div>
                    <div class="changelog__main d-flex">
                        <div>
                            <div>Current status</div>
                            <div>Assignment name</div>
                            <div>SKU</div>
                            <div>ID</div>
                            <div>Designer</div>
                            <div>Created Date</div>
                            <div>Deadline</div>
                            <div>Client</div>
                            <div>Overall Designer compensation $</div>
                            <div>Designer transaction ID</div>
                            <div>Overall Client price $</div>
                            <div>Client transaction ID</div>
                        </div>
                        <v-divider vertical class="changelog__divider mx-5"/>
                        <div class="text-right">
                            <div>{{ $config.project.statusLabels[projectData.status] }}</div>
                            <div>{{ fieldValue(projectData.title ) }}</div>
                            <div>{{ fieldValue(projectData.sku ) }}</div>
                            <div>{{ fieldValue(projectData.id ) }}</div>
                            <div>{{ fieldValue(projectData.designer_email ) }}</div>
                            <div>{{ fieldValue(projectData.created_at ) }}</div>
                            <div>{{ fieldValue(projectData.estimated_date ) }}</div>
                            <div>{{ fieldValue(projectData.user_email ) }}</div>
                            <div>{{ fieldValue(projectData.designer_amount ) }}</div>
                            <div>{{ fieldValue(projectData.designer_order_id ) }}</div>
                            <div>{{ fieldValue(projectData.client_amount ) }}</div>
                            <div>{{ fieldValue(projectData.client_order_id ) }}</div>
                        </div>
                    </div>

                    <div class="mt-7 changelog__title">Events</div>
                    <div class="mt-11 pos-rel">
                        <div v-if="loadingEvent" class="page-preloader"/>
                        <template v-if="events.length > 0">
                            <changelog-event v-for="item in events" :info="item" :key="`event_${item.id}`"/>
                        </template>
                        <div v-else class="changelog__title_sub py-16">No events</div>
                    </div>
                </div>
                <div class="text-sm-center mt-4 mb-6" v-if="pagination.total_pages > 1">
                    <v-pagination
                        :value="pagination.current_page"
                        :length="pagination.total_pages"
                        :total-visible="6"
                        class="pf-pagination"
                        color="#515151"
                        next-aria-label="Next"
                        previous-aria-label="Previous"
                        @input="changePage"/>
                </div>
            </div>
        </template>
    </v-container>
</template>

<script>
    import ChangelogEvent from "../../components/changelog/ChangelogEvent";
    import { projectApi } from "@/api";
    export default {
        name: "changelog",
        components: { ChangelogEvent },
        data() {
            return {
                loading: false,
                loadingEvent: false,
                projectData: null,
                events: [],
                pagination: {
                    count: null,
                    current_page: null,
                    links: null,
                    per_page: null,
                    total: null,
                    total_pages: null
                }
            }
        },
        computed: {
            fieldValue() {
                return field => {
                    return field?.toString() ? field : "-"
                }
            },
            eventsParams() {
                return { page: Math.abs(this.$route.query.page) || 1 };
            }
        },
        methods: {
            async fetchChangelogData () {
                this.loading = true
                const allPromise = Promise.all([
                    projectApi.projectChangelogCurrent(this.$route.params.id),
                    projectApi.projectChangelogEvents(this.$route.params.id, this.eventsParams)
                ]);
                try {
                    const [{ data: projectData }, { data: eventsData }] = await allPromise;
                    this.projectData = projectData.data
                    this.events = eventsData.data
                    this.pagination = eventsData.meta.pagination
                } catch (e) {
                    console.error(e)
                } finally {
                    this.loading = false
                }
            },
            async changePage (page) {
                if (this.pagination.current_page !== page) {
                    this.loadingEvent= true
                    await this.queryReplaceHandler(page);
                    try {
                        const { data: eventsData } = await projectApi.projectChangelogEvents(this.$route.params.id, this.eventsParams)
                        this.events = eventsData.data;
                        this.pagination = eventsData.meta.pagination;
                    } catch (error) {
                        console.error(error)
                    } finally {
                        this.loadingEvent = false;
                    }
                }
            },
            async queryReplaceHandler (query) {
                await this.$router.push({
                    query: {
                        ...this.$route.query,
                        "page": query
                    }
                });
            }
        },
        mounted() {
            this.fetchChangelogData()
        }
    }
</script>

<style scoped lang="scss">
.changelog {
    &__title {
        font-weight: 450;
        font-size: 36px;
        line-height: 1.1;
        color: #464646;

        &_sub {
            font-size: 24px;
        }
    }

    &__divider {
        border-color: #888888;
    }

    &__main {
        font-weight: 450;
        font-size: 18px;
        line-height: 1.5;
        color: #464646;
    }
}
</style>
