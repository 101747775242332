<template>
    <div class="changelog-event mt-5">
        <div class="d-flex justify-space-between">
            <div class="flex-1">Event</div>
            <div class="text-right flex-1">{{ info.type_title }}</div>
        </div>
        <div class="d-flex justify-space-between">
            <div class="flex-1">Value change</div>
            <div class="text-right flex-1">{{ info.value }}</div>
        </div>
        <div class="d-flex justify-space-between">
            <div class="flex-1">Timestamp</div>
            <div class="text-right flex-1">{{ info.created_at }}</div>
        </div>
        <div class="d-flex justify-space-between">
            <div class="flex-1">Who?</div>
            <div class="text-right flex-1">{{ info.created_by_email }}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "changelog-event",
        props: {
            info: {
                type: Object,
                default() {
                    return {}
                }
            }
        }
    }
</script>

<style scoped lang="scss">
.changelog-event {
    padding: 14px 14px 20px 14px;
    border: 1px solid #464646;
    font-style: normal;
    font-weight: 450;
    font-size: 18px;
    line-height: 150%;
    color: #464646;
    max-width: 407px;
}
</style>
